import React from 'react'
import { Back } from '../../back/Back'

export const Popeth = ({ className }) => {
  return (
    <div className="projects__page-body">
      <Back />I will eventually fill this out but really you can just checkout this website
    </div>
  )
}
